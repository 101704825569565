import * as React from 'react';

import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized, Typography } from 'src/app/components';
import { AppProps, SeoObject } from 'src/app/types';

const seo: SeoObject = {
  title: 'Career',
};

// TODO: i18n
const CareerPage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={'Career'}
      title={<Localized dictKey={'staticLocales:pages./career.link label'} />}
      {...{ ...props, seo }}
    >
      <Typography variant={'body1'}>Content</Typography>
    </AppPageLayout>
  );
};
export default CareerPage;
